import React from "react";
import { useEffect, useState } from "react";
import Summary from "./Summary";
import { ReportResponse } from "../types";
import Loader from "./Loader";
import { getReportData } from "./service";
import { DEFAULT_REPORT } from "../utils";
import "./css/rocket.css";
import "./css/custom.css";
import TaskItem from "./TaskItem";

function App() {
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [reportData, setReportData] = useState<ReportResponse>({
    tasks: [],
    report: DEFAULT_REPORT,
  });

  useEffect(() => {
    const reportId = window.location.pathname.replace("/", "");
    getReportData(reportId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response
            .text()
            .then((text) => JSON.parse(text))
            .then((text) => {
              throw new Error(text.message);
            });
        }
      })
      .then((response) => {
        setReportData(response);
        setErrorMsg("");
      })
      .catch((err) => {
        setErrorMsg(err?.message);
      })
      .finally(() => setLoading(false));
  }, []);

  const renderTasks = () => (
    <ul className="list-group list-group-flush">
      {reportData.tasks.map((task) => {
        return (
          <TaskItem
            key={`task_item_${task.taskKey}`}
            task={task}
            report={reportData.report}
          />
        );
      })}
    </ul>
  );

  const renderSummary = () => <Summary tasks={reportData.tasks} />;

  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col-12">
          <div className="card bg-white border-light">
            <div className="card-body p-4">
              {errorMsg.length > 0 && (
                <div className="alert alert-danger">{errorMsg}</div>
              )}
              {loading ? (
                <Loader />
              ) : (
                <>
                  {reportData.report.title.length > 0 && (
                    <h5>{reportData.report.title}</h5>
                  )}
                  {reportData.report.description.length > 0 && (
                    <h6>{reportData.report.description}</h6>
                  )}
                  {renderTasks()}
                  <hr />
                  {renderSummary()}

                  {reportData.report.additionalComment.length > 0 && (
                    <div className="text-muted small mt-3 additional-comments">
                      {reportData.report.additionalComment}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
