import { API } from "./config";

export const getReportData = (reportUrl: string) =>
  fetch(`${API}userreports`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      reportUrl,
      action: "get_report",
    }),
  })
