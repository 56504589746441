import React, { useMemo } from "react";
import { Task } from "../types";
import { formatter, getEarningsNumberOnly } from "../utils";

interface IProps {
  tasks: Task[];
}

const Summary = ({ tasks }: IProps) => {
  const summaryData = useMemo(() => {
    const earnings = tasks.reduce((allEarnings, task) => {
      const taskTimes = task.taskTimes
        .filter((t) => t.type === "TIME")
        .reduce((a, b) => a + b.durationSeconds, 0);
      const taskEarnings = getEarningsNumberOnly(taskTimes, task.hourlyRate);
      const taskAmountEarnings = task.taskTimes
        .filter((t) => t.type === "AMOUNT")
        .reduce((a, b) => a + (b.amount || 0), 0);
      return allEarnings + taskEarnings + taskAmountEarnings;
    }, 0);

    return earnings;
  }, [tasks]);

  if (!tasks.length) return null;

  return (
    <div className="w-50 text-rights ml-auto">
      <table className="table table-sm  small">
        <thead>
          <tr>
            <th>Summary</th>
            <th>
              <strong>{formatter.format(summaryData)}</strong>
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default Summary;
