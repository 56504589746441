import React from "react";

interface IProps {
  small?: boolean;
  customClassName?: string;
}

const Loader = ({ small, customClassName }: IProps) => (
  <div
    className={`d-flex justify-content-center ${
      small ? "" : "my-5"
    } ${customClassName}`}
  >
    <div
      className="spinner-grow text-warning"
      role="status"
      style={small ? { width: "1rem", height: "1rem" } : {}}
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default Loader;
