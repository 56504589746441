import { Report } from "./types";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const formatDate = (date: number) => {
  const dateFixed = new Date(date * 1000);
  return (
    dateFixed.toLocaleDateString("en-US") +
    " " +
    dateFixed.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })
  );
};
export const formatTimeSpent = (timeInSec: number) => {
  if (timeInSec === 0) return "-";
  if (timeInSec < 60) return `< 1min`;
  if (timeInSec < 3600) return `${Math.round(timeInSec / 60)} min`;
  const fullHours = Math.floor(timeInSec / 3600);
  const minutes = Math.round((timeInSec - 3600 * fullHours) / 60);
  return `${fullHours}hr ${minutes}min`;
};
export const getEarnings = (timeSpent: number, hourlyRate: number) =>
  formatter.format(
    Math.round((hourlyRate / 60) * Math.round(timeSpent / 60) * 100) / 100
  );
export const getEarningsNumberOnly = (timeSpent: number, hourlyRate: number) =>
  Math.round((hourlyRate / 60) * Math.round(timeSpent / 60) * 100) / 100;

export const DEFAULT_REPORT: Report = {
  additionalComment: "",
  description: "",
  generatedTime: "",
  includeTaskTimeList: false,
  title: "",
};
