import React, { useState } from "react";
import { Report, Task } from "../types";
import { formatDate, formatter, formatTimeSpent, getEarnings } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  task: Task;
  report: Report;
}

const TaskTimeItems = ({ task }: IProps) => (
  <table className="table table-sm small">
    <thead>
      <tr>
        <th>Start</th>
        <th>End</th>
        <th>Time spent</th>
        <th>Costs</th>
      </tr>
    </thead>
    <tbody>
      {task.taskTimes.map((item) => (
        <React.Fragment key={`task_time_item_${item.taskTimeKey}`}>
          <tr>
            <td>{formatDate(item.start)}</td>
            <td>{formatDate(item.stop)}</td>
            <td>{formatTimeSpent(item.durationSeconds)}</td>
            <td>{getEarnings(item.durationSeconds, task.hourlyRate)}</td>
          </tr>
          {item.description && item.description.length > 0 && (
            <tr>
              <td colSpan={4} className="text-muted">
                {item.description}
              </td>
            </tr>
          )}
        </React.Fragment>
      ))}
    </tbody>
  </table>
);

const TaskItem = ({ task, report }: IProps) => {
  const [showTaskTimes, setShowTaskTimes] = useState(false);
  const toggleShowTaskTimes = () => setShowTaskTimes(!showTaskTimes);

  const noOfTaskTimeItems = task.taskTimes.length;
  const allTimes = task.taskTimes.reduce((a, b) => a + b.durationSeconds, 0);
  const taskItemBody = () => (
    <div>
      <div className="small">
        Task: <strong>{task.name}</strong>
      </div>
      {task.description.length > 0 && (
        <div className="small text-muted">{task.description}</div>
      )}
      <div className="d-flex">
        <div className="small text-muted m">
          Hourly rate: <strong>{formatter.format(task.hourlyRate)}</strong>
        </div>
        <div className="mx-2 small text-muted">|</div>
        <div className="small text-muted">
          Time spent: <strong>{formatTimeSpent(allTimes)}</strong>
        </div>
        <div className="mx-2 small text-muted">|</div>
        <div className="small text-muted">
          Total: <strong>{getEarnings(allTimes, task.hourlyRate)}</strong>
        </div>
      </div>
    </div>
  );

  return (
    <li className="list-group-item">
      {report.includeTaskTimeList ? (
        <div className="small d-flex align-items-center justify-content-between">
          {taskItemBody()}
          <button
            className="btn btn-sm text-muted border-0 p-0 mt-1"
            onClick={toggleShowTaskTimes}
          >
            <small>
              Details ({noOfTaskTimeItems}{" "}
              {noOfTaskTimeItems > 1 ? "items" : "item"})
              <FontAwesomeIcon
                icon={showTaskTimes ? faChevronUp : faChevronDown}
                className="ml-1"
              />
            </small>
          </button>
        </div>
      ) : (
        taskItemBody()
      )}
      {showTaskTimes && <TaskTimeItems task={task} report={report} />}
    </li>
  );
};

export default TaskItem;
